import { useState, useEffect } from 'react';

export const useCurrentDeviceHeighPicker = () => {
  const [height, setHeight] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight - 1);
    };

    // Проверяем, доступен ли объект window перед добавлением слушателя событий
    if (typeof window !== 'undefined') {
      setHeight(window.innerHeight - 1);
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return height;
};
