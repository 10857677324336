import { FC } from 'react';
import cn from 'classnames';
import { SectionProps } from './Section.props';
import { Container } from '@/components/common/Container';
import { Breadcrumbs } from '@/components/navigation/Breadcrumbs';
import { useInView } from 'react-intersection-observer';
import { LazyMotion, m } from 'framer-motion';

export const Section: FC<SectionProps> = ({
  className,
  children,
  breadcrumbs,
  lazy = true,
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Анимация будет срабатывать только один раз
    rootMargin: '0px 0px -80px 0px', // Область видимости: 80px снизу
  });

  const lazyAnimation = () =>
    import('../../../utils/lazyAnimation').then(res => res.default);

  if (!lazy) {
    return (
      <section
        className={cn(
          'bg-white dark:bg-darkFullDark dark:text-darkFullLight',
          className,
          {
            'py-8 md:py-10 lg:py-12': !breadcrumbs,
            'pb-15 pt-5 md:pb-25 md:pt-9': breadcrumbs,
          },
        )}
      >
        <Container>
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          {children}
        </Container>
      </section>
    );
  }

  return (
    <LazyMotion features={lazyAnimation}>
      <m.section
        className={cn(
          'bg-white dark:bg-darkFullDark dark:text-darkFullLight',
          className,
          {
            'py-8 md:py-10 lg:py-12': !breadcrumbs,
            'pb-15 pt-5 md:pb-25 md:pt-9': breadcrumbs,
          },
        )}
        ref={ref}
        initial={{ opacity: 0, y: 80 }}
        animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : 80 }}
        transition={{ duration: 0.25 }}
      >
        <Container>
          {breadcrumbs && <Breadcrumbs breadcrumbs={breadcrumbs} />}
          {children}
        </Container>
      </m.section>
    </LazyMotion>
  );
};
