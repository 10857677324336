import { FC } from 'react';
import cn from 'classnames';
import { motion } from 'framer-motion';

import { IAnimatedComponent } from './AnimatedComponent.types';

export const AnimatedComponent: FC<IAnimatedComponent> = ({
  children,
  className = '',
  variants,
  initial = 'hidden',
  ...props
}) => {
  const defaultVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  };

  return (
    <motion.div
      exit="hidden"
      variants={variants || defaultVariants}
      initial={initial}
      animate="visible"
      transition={{ duration: 0.2, ease: 'easeInOut' }}
      className={cn(className)}
      {...props}
    >
      {children}
    </motion.div>
  );
};
