import { FC } from 'react';
import cn from 'classnames';

import { SocialListProps } from './SocialList.props';

import InstagramIcon from '@/public/images/social-instagram.svg';
import FacebookIcon from '@/public/images/social-fb.svg';
import LinkedinIcon from '@/public/images/social-linked.svg';
import { useLocaleHook } from '@/hooks';

export const SocialList: FC<SocialListProps> = ({
  data,
  iconColor,
  className,
}) => {
  const locale = useLocaleHook();
  const isUk = locale === 'uk';

  const conditionsForClass = {
    ['text-white']: iconColor === 'white',
    ['text-textPrimary dark:text-darkFullLight']: iconColor === 'black',
  };
  return (
    <ul className={cn('inline-flex gap-6', className)}>
      {data.map(link => (
        <li
          key={link.socialMediaTitle}
          className="group relative h-[40px] w-[40px]"
        >
          {iconColor === 'white' && (
            <span className="absolute bottom-[1px] left-1/2 h-[95%] w-[95%] -translate-x-1/2 rounded transition-colors duration-300 group-hover:bg-white" />
          )}
          <a
            href={link.socialMediaLink}
            rel="noreferrer noopener nofollow"
            target="_blank"
            className={cn(
              conditionsForClass,
              'hover:gray-scale-red-diff absolute inset-0 transition-colors duration-300 group-hover:text-accentAdditional group-focus:text-accentAdditional',
            )}
            aria-label={
              isUk
                ? `Перейти на нашу сторінку в ${link.socialMediaTitle}`
                : `Go to our ${link.socialMediaTitle} page`
            }
          >
            {link.socialMediaTitle == 'Instagram' && (
              <InstagramIcon className="aspect-square w-[40px]" />
            )}

            {link.socialMediaTitle == 'Facebook' && (
              <FacebookIcon className="aspect-square w-[40px]" />
            )}

            {link.socialMediaTitle == 'LinkedIn' && (
              <LinkedinIcon className="aspect-square w-[40px]" />
            )}
          </a>
        </li>
      ))}
    </ul>
  );
};
