import { gql } from '@apollo/client';

export const SEARCH_QUERY = gql`
  query SearchContent(
    $searchQuery: String!
    $language: LanguageCodeFilterEnum
  ) {
    pages(where: { search: $searchQuery, language: $language }) {
      nodes {
        id
        uri
        title
      }
    }
    allNews(where: { search: $searchQuery, language: $language }) {
      nodes {
        id
        uri
        title
      }
    }
    allProducts(where: { search: $searchQuery, language: $language }) {
      nodes {
        id
        uri
        title
      }
    }
  }
`;
