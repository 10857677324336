import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';

import Burger from '@/public/icons/burger.svg';
import Close from '@/public/icons/close.svg';
import CloseCircle from '@/public/icons/close-circle.svg';
import Eye from '@/public/icons/eye.svg';
import SearchIcon from '@/public/icons/search.svg';
import DroppedIcon from '@/public/icons/arrow-navLink.svg';
import Plus from '@/public/icons/plus.svg';
import Minus from '@/public/icons/minus.svg';

export const icons = {
  burger: Burger,
  close: Close,
  close_circle: CloseCircle,
  eye: Eye,
  search: SearchIcon,
  dropped: DroppedIcon,
  plus: Plus,
  minus: Minus,
};

export type IconButtonTypes = keyof typeof icons;

export interface IconButtonProps
  extends DetailedHTMLProps<
    ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  icon: IconButtonTypes;
}
