import { BreadcrumbsProps } from '@/types/types';
import { useRouter } from 'next/router';
import { transformUrl } from '@/utils/transformUrl';
import { decodeText } from '@/utils/decodeText';

export const useCrumbsModifierHook = (breadcrumbs: BreadcrumbsProps[]) => {
  const router = useRouter();
  return breadcrumbs.map((crumb, index) => {
    const { text, url } = crumb;

    const decodedText = decodeText(text);

    if (index === 0 && router.locale !== 'uk') {
      return {
        text: 'Home',
        url: 'https://www.microkhim.com.ua/en',
      };
    } else {
      if (url) {
        return {
          text: decodedText ?? '',
          url: transformUrl(url),
        };
      }
      return {
        text: decodedText ?? '',
        url: '/',
      };
    }
  });
};
