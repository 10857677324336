import { FC } from 'react';
import Link from 'next/link';
import { ROUTES } from '@/utils/routes';
import { useLocaleHook } from '@/hooks';

import Logo from '@/public/icons/logo.svg';
import LogoEn from '@/public/icons/logo_en.svg';

export const SiteLogo: FC = () => {
  const locale = useLocaleHook();
  const isUk = locale === 'uk';
  const currentClass =
    'h-8 w-[130px] text-accentAdditional transition-colors duration-300 hover:text-accentMain md:h-[46px] md:w-[180px] lg:h-[54px] lg:w-[214px] dark:text-darkFullLight dark:hover:text-accentMain';

  return (
    <Link
      href={ROUTES.HOME}
      aria-label={isUk ? 'Головна сторінка' : 'Main page'}
    >
      {isUk ? (
        <Logo className={currentClass} />
      ) : (
        <LogoEn className={currentClass} />
      )}
    </Link>
  );
};
