import { NextPage } from 'next';
import { FC, FunctionComponent, useEffect } from 'react';
import { Header } from '@/layout/Header';

import { LayoutProps } from './Layout.props';
import { Footer } from '../Footer';
import { useDarkModeContext } from '@/hooks/useContext';
import { useMediaQueryWidthPicker } from '@/hooks';

export const Layout: FC<LayoutProps> = ({ children, ...props }) => {
  const { menus, footerData } = props;
  const theme = useDarkModeContext();
  const { device } = useMediaQueryWidthPicker();

  useEffect(() => {
    const html = document.documentElement;
    if (theme === 'dark') {
      html.classList.remove('light');
      html.classList.add('dark');
    } else {
      html.classList.remove('dark');
      html.classList.add('light');
    }
  }, [theme]);

  useEffect(() => {
    const isGrayScaled =
      localStorage.getItem('grayscale') == 'true' ? true : false;
    if (isGrayScaled) {
      ['header', 'footer', 'main'].forEach(el =>
        document.querySelector(el)?.classList.add('grayscale'),
      );
    }
  }, []);

  useEffect(() => {
    const isImagesHidden =
      localStorage.getItem('hide-images') == 'true' ? true : false;
    isImagesHidden
      ? document.documentElement.classList.add('hide-images')
      : null;
  }, []);

  useEffect(() => {
    const font = localStorage.getItem('font');
    const html = document.documentElement;
    const screenWidth = window.innerWidth;
    if (font) {
      const fontSize =
        screenWidth < 768
          ? font === 'base'
            ? '16px'
            : font === 'large'
            ? '19px'
            : '22px'
          : font === 'base'
          ? '18px'
          : font === 'large'
          ? '21px'
          : '24px';
      html.style.fontSize = fontSize;
      return;
    }
    html.style.fontSize = screenWidth < 768 ? '16px' : '18px';
  }, [device]);

  return (
    <div className="flex h-full min-h-screen flex-col text-base md:text-lg">
      <Header menus={menus} />

      <main className="flex-grow pt-[73px] md:pt-[111px] ">{children}</main>
      <Footer footerData={footerData} />
    </div>
  );
};

export const withLayout = <T extends LayoutProps>(
  Component: NextPage<T> | FunctionComponent<T>,
) => {
  return function withLayoutComponent(props: T) {
    return (
      <Layout {...props}>
        <Component {...props} />
      </Layout>
    );
  };
};
