import { DarkModeContext, SetDarkModeContext } from '@/utils/themeContext';
import { useContext } from 'react';

export function useDarkModeContext() {
  return useContext(DarkModeContext);
}

export function useSetDarkModeContext() {
  return useContext(SetDarkModeContext);
}
