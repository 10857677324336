import { useState } from 'react';

export const useCategoryFilter = () => {
  const [activeCategory, setActiveCategory] = useState<string | null>(null);

  const handleCategoryClick = (categorySlug: string) => {
    setActiveCategory(categorySlug);
  };

  return { activeCategory, handleCategoryClick };
};
