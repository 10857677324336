import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';

export const useTableHeadersHook = () => {
  const { t } = useTranslation('common');

  const tableTitleName = t('geographyTableNameTitle');
  const tableTitleForm = t('geographyTableFormTitle');
  const tableTitleSubstance = t('geographyTableSubstanceTitle');
  const tableTitleCertificate = t('geographyTableCertificateTitle');
  const tableTitlePeriod = t('geographyTablePeriodTitle');

  const headers = useMemo(
    () => [
      { Header: tableTitleName, accessor: 'name' },
      { Header: tableTitleForm, accessor: 'form' },
      { Header: tableTitleSubstance, accessor: 'substance' },
      { Header: tableTitleCertificate, accessor: 'certificate' },
      { Header: tableTitlePeriod, accessor: 'period' },
    ],
    [
      tableTitleCertificate,
      tableTitleForm,
      tableTitleName,
      tableTitlePeriod,
      tableTitleSubstance,
    ],
  );
  return headers;
};
