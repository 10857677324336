export const ROUTES = {
  HOME: '/',
  ABOUT_US: '/about-us',
  COMPANY_HISTORY: '/about-us/company-history',
  PHARMACEUTICAL_DEVELOPMENT: '/about-us/pharmaceutical-development',
  QUALITY_CONTROL: '/about-us/quality-control',
  GEOGRAPHY_OF_BUSINESS: '/about-us/geography-of-business',
  MEDIA_ABOUT_US: '/about-us/media-about-us',
  NEWS: '/news',
  PRODUCTS: '/products',
  CONTACTS: '/contacts',
  PHARMACOVIGILANCE: '/farmakonaglyad',
  PRIVACY_POLICY: '/privacy-policy',
  FAQ: '/about-us/faq',
};
