import { useEffect, useState } from 'react';
import { useMediaQueryWidthPicker } from './useMediaQueryWidthPicker';

export const useSliderParamsGenerator = (
  children: number,
  width: number,
  tabletShowSlider?: number | undefined,
  desktopShowSlider?: number | undefined,
): number => {
  const [calculatedParams, setCalculatedParams] = useState(1);

  const tabletWidth = 768;
  const desktopWidth = 1280;
  const { screenWidth } = useMediaQueryWidthPicker();

  useEffect(() => {
    if (screenWidth >= desktopWidth) {
      desktopShowSlider
        ? setCalculatedParams(desktopShowSlider)
        : children <= 4
        ? setCalculatedParams(children)
        : setCalculatedParams(4);
    } else if (screenWidth >= tabletWidth) {
      tabletShowSlider
        ? setCalculatedParams(tabletShowSlider)
        : children <= 3
        ? setCalculatedParams(children)
        : setCalculatedParams(3);
    } else {
      setCalculatedParams(Math.floor(screenWidth / width));
    }
  }, [screenWidth]);

  return calculatedParams;
};
