import { SocialList } from '@/components/cards/SocialList';
import { useLocaleHook, useMediaQueryWidthPicker } from '@/hooks';
import { IFooter } from '@/types/types';
import Image from 'next/image';
import Link from 'next/link';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { Contacts } from './Contacts';

export const Footer: FC<{ footerData: IFooter }> = ({ footerData }) => {
  const [isMounted, setIsMounted] = useState(false);
  const { device } = useMediaQueryWidthPicker();

  const locale = useLocaleHook();
  const isUk = locale === 'uk';

  const { t } = useTranslation('common');
  const title = t('heroTitle');

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { footerAbout, footerContacts, footerMenu } = footerData;
  const { logo } = footerAbout;

  return (
    <>
      {isMounted && (
        <footer className="bg-primaryDark text-white">
          <div className="container flex flex-col items-center gap-10 py-10 md:grid md:grid-cols-[repeat(2,_minmax(0,_1fr))] md:grid-rows-[96px,_40px,_auto] md:gap-y-7 md:px-8 md:pb-8 md:pt-20 lg:grid-cols-[26.23%,_4.6%,_20.56%,_auto,_36%,_13.8%] lg:grid-rows-[repeat(3,_auto)] lg:gap-0 lg:gap-y-12">
            <div className="flex	 justify-center md:h-full md:justify-start mdOnly:order-1">
              <Link
                href={'/'}
                aria-label={isUk ? 'Головна сторінка' : 'Main page'}
              >
                <Image
                  className="!block"
                  src={logo.mediaItemUrl}
                  alt={logo.altText}
                  width={
                    device == 'mobile' ? 140 : device == 'tablet' ? 180 : 214
                  }
                  height={
                    device == 'mobile' ? 35 : device == 'tablet' ? 46 : 54
                  }
                />
              </Link>
            </div>
            <ul className="grid gap-7 text-center text-base md:h-full md:flex-col md:justify-between md:gap-0 md:text-start md:text-lg mdOnly:order-2 mdOnly:flex lg:col-span-3 lg:col-start-3 lg:row-span-1 lg:grid-cols-[50%,_50%] lg:flex-row">
              {footerMenu.map(item => (
                <li key={item.label} className="lg:flex lg:items-center">
                  <Link href={item.uri}>{item.label}</Link>
                </li>
              ))}
            </ul>{' '}
            <Contacts />
            <SocialList
              data={footerContacts}
              iconColor="white"
              className="mdOnly:order-3 lg:col-start-6"
            />
            <p className="footer-alert !mb-0 text-center text-sm leading-[14px] text-primaryLight md:text-start mdOnly:order-4 lg:col-span-3 lg:col-start-5	 lg:row-start-3	lg:self-end">
              {footerAbout.alert}
            </p>
            <p className="!mb-0 text-center text-sm leading-[14px] text-primaryLight md:col-span-2 md:pt-8 mdOnly:order-6 lg:col-span-1 lg:col-start-3 lg:row-start-3 lg:self-end lg:p-0 lg:text-start">
              {title} &copy; {new Date().getFullYear()} {footerAbout.copyright}
            </p>
          </div>
        </footer>
      )}
    </>
  );
};
