import { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import cn from 'classnames';

interface ModalProps {
  children: ReactNode;
  className?: string;
  closeModal: () => void;
  isOpen: boolean;
  variant?: 'default' | 'search';
}

export const Modal = ({
  children,
  className,
  closeModal,
  isOpen,
  variant = 'search',
}: ModalProps): JSX.Element => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[9999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center px-4 py-20 text-center md:py-32">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-50"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-300"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-50"
            >
              <Dialog.Panel
                className={cn(
                  {
                    '': variant == 'default',
                    'h-[600px] w-full max-w-3xl transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:border-[2px] dark:border-darkFullLight dark:bg-darkFullDark':
                      variant == 'search',
                  },
                  className,
                )}
              >
                {children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
