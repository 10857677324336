import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import cn from 'classnames';

import SearchIcon from '@/public/images/search.svg';
import { useLazyQuery } from '@apollo/client';
import { SEARCH_QUERY } from '@/data/searchQuery';
import { ClearButton } from '@/components/buttons/ClearButton';
import { SearchProps } from './Search.props';

export const Search: FC<SearchProps> = ({
  placeholder = 'Пошук...',
  className,
  setLoading,
  setData,
  setSearchValue,
}) => {
  const [inputValue, setInputValue] = useState(''); // Значение ввода
  const [executeSearchQuery, { loading, data }] = useLazyQuery(SEARCH_QUERY);
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);

  const language = router.locale === 'en' ? 'EN' : 'UK';

  useEffect(() => {
    setLoading(loading);

    if (data) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const values = Object?.values(data).flatMap((item: any) => item?.nodes);
      setData(values);
    }
  }, [data, loading, setData, setLoading]);

  useEffect(() => {
    setLoading(loading);
    executeSearchQuery({
      variables: {
        searchQuery: '',
        language: language,
      },
    });
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setSearchValue(inputValue);
      executeSearchQuery({
        variables: {
          searchQuery: inputValue,
          language: language,
        },
      });
    }, 250);

    return () => {
      clearTimeout(timer);
    };
  }, [inputValue]);

  useEffect(() => {
    const autoFocusTimer = setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, 100);

    return () => {
      clearTimeout(autoFocusTimer);
    };
  }, []);

  useEffect(() => {
    if (inputValue == '') {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [inputValue]);

  return (
    <div
      className={cn(
        'relative h-12 overflow-hidden rounded-base border border-accentAdditional bg-white pl-12 pr-16 text-accentAdditional focus-within:border-accentMain focus-within:text-accentMain',
        className,
      )}
    >
      <SearchIcon className="absolute left-4 top-1/2 aspect-square w-5 -translate-y-1/2" />
      <ClearButton
        className="absolute right-3.5 top-1/2 -translate-y-1/2 rounded-[2px] border border-primaryLight px-1.5 py-[1px] text-sm text-textPrimary transition-all duration-100 hover:text-accentMain focus:text-accentMain active:border active:border-accentMain"
        onClick={() => setInputValue('')}
      />

      <input
        ref={inputRef}
        placeholder={placeholder}
        className="w-full border-none bg-inherit py-[10px] text-primaryDark"
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setInputValue(e.target.value.trim())
        }
      />
    </div>
  );
};
