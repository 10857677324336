import { useState } from 'react';
import cn from 'classnames';
import Link from 'next/link';

import { IconButton } from '@/components/buttons/IconButton';

import { NavbarProps } from './Navbar.props';
import { AnimatedComponent } from '@/components/common/AnimatedComponent';
import { useLocaleHook } from '@/hooks';

export const NavbarNotDesktop = ({ menus }: NavbarProps): JSX.Element => {
  const [dropped, setDropped] = useState(false);
  const locale = useLocaleHook();
  const isUk = locale == 'uk';

  return (
    <nav className="w-full text-center">
      <ul className="flex flex-col text-start text-xl">
        {menus.map((menu, index) => (
          <li
            key={menu.id}
            className={cn('group w-full', {
              'my-10': index == menus.length - 1,
            })}
          >
            <div
              className={cn({
                "relative before:absolute before:bottom-0 before:left-0 before:h-[1px] before:w-full before:bg-primaryLight before:content-['']":
                  index < menus.length - 2,
                'flex justify-center': index == menus.length - 1,
              })}
            >
              <Link href={`/${menu.uri}` ?? ''} legacyBehavior>
                <a
                  className={cn('inline-block py-5', {
                    'text-primaryDark dark:text-darkFullLight':
                      index != menus.length - 1,
                    'inline-block rounded border border-accentMain bg-accentMain !py-3.5 px-6 text-white transition-colors duration-300 hover:bg-transparent hover:!text-accentMain focus:bg-transparent focus:!text-accentMain':
                      index == menus.length - 1,
                  })}
                >
                  {menu.label}
                </a>
              </Link>
              {index == 0 && (
                <IconButton
                  icon="dropped"
                  className={cn('absolute right-0 top-1/2 -translate-y-1/2', {
                    'rotate-180': dropped,
                  })}
                  area-label={
                    !dropped
                      ? isUk
                        ? 'Розгорнути меню'
                        : 'Expand menu'
                      : isUk
                      ? 'Згорнути меню'
                      : 'Collapse menu'
                  }
                  onClick={() => setDropped(!dropped)}
                />
              )}
            </div>

            {!!menu.children.length && dropped && (
              <AnimatedComponent
                className="origin-top"
                variants={{
                  hidden: { scaleY: 0, opacity: 0 },
                  visible: { scaleY: 1, opacity: 1 },
                }}
              >
                <ul className="py-2 pl-16 leading-6 ">
                  {menu.children.map(item => (
                    <li key={item.id} className="cursor-pointer">
                      <Link
                        className="inline-block whitespace-pre-wrap py-[14px] text-primaryDark transition-colors duration-300 hover:text-accentMain dark:text-darkFullLight dark:hover:text-accentAdditional"
                        href={`../${item.uri}`}
                      >
                        {item.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </AnimatedComponent>
            )}
          </li>
        ))}
      </ul>
      <a
        className="py-5 text-textPrimary transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional dark:text-darkFullLight dark:hover:text-accentMain"
        href="tel:+380952869014"
      >
        +380952869014
      </a>
    </nav>
  );
};
