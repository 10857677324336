import { AnimatedComponent } from '@/components/common/AnimatedComponent';
import cn from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { NavbarProps } from './Navbar.props';

export const NavbarDesktop = ({
  menus,
  isRedHeroBg,
}: NavbarProps): JSX.Element => {
  const router = useRouter();
  const path = router.asPath;

  return (
    <nav>
      <ul className="flex items-center gap-10 text-base">
        {menus.map((menu, index) => {
          const isLast = index === menus.length - 1;
          const uri = menu.uri as string;
          const uriEndPoint =
            uri
              .split('/')
              .filter(item => !!item)
              .pop() || '';
          const isActive = path.includes(uriEndPoint);

          return (
            <li key={menu.id} className="group relative">
              <Link href={`/${menu.uri}` ?? ''} legacyBehavior>
                <a
                  className={cn('py-3.5 leading-5', {
                    'text-white dark:text-darkFullDark': isRedHeroBg,
                    'text-primaryDark dark:text-darkFullLight': !isRedHeroBg,
                    'ml-5 inline-block rounded border px-6 transition-colors duration-300 ':
                      isLast,
                    'border-white bg-white !text-primaryDark hover:bg-transparent hover:!text-white focus:bg-transparent focus:!text-white dark:border-darkFullDark dark:bg-darkFullDark dark:!text-darkFullLight dark:hover:bg-transparent dark:hover:!text-darkFullDark dark:focus:bg-transparent dark:focus:!text-darkFullDark':
                      isLast && isRedHeroBg,
                    'border-accentMain bg-accentMain !text-white hover:bg-transparent hover:!text-accentMain focus:bg-transparent focus:!text-accentMain':
                      isLast && !isRedHeroBg,
                    'relative before:absolute before:bottom-[5px] before:left-0 before:h-[1px] before:w-full before:bg-white dark:before:bg-darkFullDark':
                      isActive && !isLast && isRedHeroBg,
                    'dark:before:bg-gray-scale-red-diff relative before:absolute before:bottom-[5px] before:left-0 before:h-[1px] before:w-full before:bg-accentMain':
                      isActive && !isLast && !isRedHeroBg,
                  })}
                >
                  {menu.label}
                </a>
              </Link>

              {!!menu.children.length && (
                <AnimatedComponent>
                  <ul className="invisible absolute -bottom-[6px] left-0 w-fit min-w-[260px] origin-top-left translate-y-full scale-0 divide-y rounded-md border bg-white px-4 py-1 opacity-0 shadow-sm transition-all group-hover:visible group-hover:scale-100 group-hover:opacity-100 dark:bg-darkFullDark">
                    {menu.children.map(item => {
                      return (
                        <li
                          key={item.id}
                          className="hidden cursor-pointer duration-300 group-hover:block"
                        >
                          <Link
                            className="inline-block whitespace-nowrap py-3 text-primaryDark transition-colors duration-300 hover:text-accentMain dark:text-darkFullLight dark:hover:text-accentMain"
                            href={`../${item.uri}`}
                          >
                            {item.label}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </AnimatedComponent>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
