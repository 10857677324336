import CloseIcon from '@/public/icons/close.svg';
import { FC } from 'react';
import cn from 'classnames';
import { useLocaleHook } from '@/hooks';

import { IconButtonProps, icons } from './IconButton.props';

export const IconButton: FC<IconButtonProps> = ({
  icon,
  className,
  ...props
}) => {
  const Icon = icons[icon];
  const locale = useLocaleHook();
  const isUk = locale == 'uk';

  switch (icon) {
    case 'close':
      return (
        <button
          className={cn(
            'transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional dark:text-darkFullLight dark:hover:text-accentMain',
            className,
          )}
          {...props}
          aria-label={isUk ? 'Закрити' : 'Close'}
        >
          <Icon className="aspect-square w-7" />
        </button>
      );

    case 'dropped':
      return (
        <button
          className={cn(
            'inline-flex h-12 w-12 items-center justify-center text-accentMain transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional dark:text-darkFullLight dark:hover:text-accentAdditional dark:focus:text-accentAdditional',
            className,
          )}
          {...props}
        >
          <Icon className="h-7 w-7" />
        </button>
      );

    case 'close_circle':
      return (
        <button
          className={cn(
            className,
            'absolute right-5 top-5 rounded-full text-white outline',
          )}
          aria-label={isUk ? 'Закрити' : 'Close'}
          {...props}
        >
          <CloseIcon className="aspect-square w-7" />
        </button>
      );

    case 'burger':
      return (
        <button
          className="text-accentMain transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional dark:text-darkFullLight dark:hover:text-accentMain"
          {...props}
          aria-label={isUk ? 'Відкрити меню навігації' : 'Open navigation menu'}
        >
          <Icon className="h-7 w-7" />
        </button>
      );

    case 'eye':
      return (
        <button
          className="text-textPrimary transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional dark:text-darkFullLight dark:hover:text-accentMain"
          {...props}
        >
          <Icon className="h-7 w-7" />
        </button>
      );

    case 'search':
      return (
        <button
          className={cn(
            'text-accentAdditional transition-colors duration-300 hover:border-accentMain hover:text-accentMain focus:border-accentMain focus:text-accentMain dark:text-darkFullLight dark:hover:text-accentMain',
            className,
          )}
          aria-label={isUk ? 'Відкрити вікно пошуку' : 'Open search window'}
          {...props}
        >
          <Icon className="aspect-square w-7" />
        </button>
      );

    case 'plus':
      return (
        <button
          className={cn(
            'text-accentAdditional transition-colors duration-300 hover:text-accentMain focus:text-accentMain disabled:opacity-30 dark:disabled:text-darkFullLight dark:disabled:opacity-100 dark:disabled:blur-[1px]',
            className,
          )}
          aria-label={isUk ? 'Збільшити розмір шрифту' : 'Increase font size'}
          {...props}
        >
          <Icon className="h-5 w-5" />
        </button>
      );

    case 'minus':
      return (
        <button
          className={cn(
            'text-accentAdditional transition-colors duration-300 hover:text-accentMain focus:text-accentMain disabled:opacity-30 dark:disabled:text-darkFullLight dark:disabled:opacity-100 dark:disabled:blur-[1px]',
            className,
          )}
          aria-label={isUk ? 'Зменшити розмір шрифту' : 'Decrease font size'}
          {...props}
        >
          <Icon className="h-5 w-5" />
        </button>
      );

    default:
      return <></>;
  }
};
