import { ButtonHTMLAttributes, FC } from 'react';
import { useLocaleHook } from '@/hooks';

export const ClearButton: FC<ButtonHTMLAttributes<HTMLButtonElement>> = ({
  onClick,
  className,
  ...rest
}) => {
  const locale = useLocaleHook();
  return (
    <button
      className={className}
      onClick={onClick}
      {...rest}
      aria-label={
        locale == 'uk' ? 'Очистити поле пошуку' : 'Clear search field'
      }
    >
      <span aria-hidden={true}>clear</span>
    </button>
  );
};
