import Link from 'next/link';
import { FC } from 'react';
import { useTranslation } from 'next-i18next';

export const Contacts: FC = () => {
  const { t } = useTranslation('common');
  const address = t('footerAddress');
  const scheduleLabel = t('schedule');
  const addressLabel = t('addressLabel');
  const contactsLabel = t('contactsLabel');
  const scheduleWork1 = t('scheduleWork_1');
  const scheduleWork2 = t('scheduleWork_2');
  const scheduleWeekends = t('scheduleWeekends');

  return (
    <>
      <div className="h-full md:order-5 md:flex md:justify-between lg:col-span-2 lg:col-start-1 lg:row-span-1 lg:w-11/12	">
        <div className="lg:w-39 mb-3	flex flex-col md:mb-0 md:w-40 ">
          <p className="!mb-1 text-base text-sm leading-[14px] text-primaryLight md:!mb-3 smOnly:text-center">
            {addressLabel}
          </p>
          <p className="!mb-0 text-sm text-primaryLight smOnly:text-center">
            {address}
          </p>{' '}
        </div>
        <div className="lg:w-39 mb-3	flex flex-col md:mb-0 md:hidden md:w-40">
          <p className="!mb-1 text-base text-sm leading-[14px] text-primaryLight md:!mb-2 smOnly:text-center">
            {contactsLabel}
          </p>
          <Link
            className="mb-2 w-fit text-base text-sm leading-[14px] text-primaryLight transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional  smOnly:w-full	smOnly:text-center"
            href="mailto:info@microkhim.com"
          >
            info@microkhim.com
          </Link>
          <Link
            className="mb-1.5 w-fit text-base text-sm leading-[14px] text-primaryLight transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional smOnly:w-full smOnly:text-center"
            href="tel:0442846012"
          >
            +38 (044) 284 60 12
          </Link>{' '}
          <Link
            className="w-fit text-base text-sm leading-[14px] text-primaryLight transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional smOnly:w-full smOnly:text-center"
            href="tel:0502169392"
          >
            +38 (050) 216 93 92
          </Link>
        </div>
        <div className="md:hidden">
          <p className="!mb-1 text-base text-sm leading-[14px] text-primaryLight md:!mb-2 smOnly:text-center">
            {scheduleLabel}
          </p>
          <p className="!mb-1.5 text-base text-sm leading-[14px] text-primaryLight smOnly:text-center">
            {scheduleWork1}
          </p>{' '}
          <p className="!mb-1.5 text-base text-sm leading-[14px] text-primaryLight smOnly:text-center">
            {scheduleWork2}
          </p>
          <p className="text-base text-sm leading-[14px] text-primaryLight smOnly:text-center">
            {scheduleWeekends}
          </p>
        </div>
      </div>
      <div className="order-6 flex lg:col-span-3 lg:col-start-3 lg:row-span-1 lg:grid-cols-[35%,_61%] lg:flex-row">
        <div className="lg:w-50	 mb-3		flex hidden w-10 flex-col self-start md:mb-0 md:flex md:w-1/2		">
          <p className="!mb-1 text-base text-sm leading-[14px] text-primaryLight md:!mb-3 smOnly:text-center">
            {contactsLabel}
          </p>
          <Link
            className="mb-2 w-fit text-base text-sm leading-[14px] text-primaryLight transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional  smOnly:w-full	smOnly:text-center"
            href="mailto:info@microkhim.com"
          >
            info@microkhim.com
          </Link>
          <Link
            className="mb-1.5 w-fit text-base text-sm leading-[14px] text-primaryLight transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional smOnly:w-full smOnly:text-center"
            href="tel:0442846012"
          >
            +38 (044) 284 60 12
          </Link>{' '}
          <Link
            className="w-fit text-base text-sm leading-[14px] text-primaryLight transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional smOnly:w-full smOnly:text-center"
            href="tel:0502169392"
          >
            +38 (050) 216 93 92
          </Link>
        </div>
        <div className="order-8  col-start-3 row-start-2 hidden md:block">
          <p className="!mb-1 text-base text-sm leading-[14px] text-primaryLight md:!mb-3 smOnly:text-center">
            {scheduleLabel}
          </p>
          <p className="!mb-1.5 text-base text-sm leading-[14px] text-primaryLight smOnly:text-center">
            {scheduleWork1}
          </p>{' '}
          <p className="!mb-1.5 text-base text-sm leading-[14px] text-primaryLight smOnly:text-center">
            {scheduleWork2}
          </p>
          <p className="text-base text-sm leading-[14px] text-primaryLight smOnly:text-center">
            {scheduleWeekends}
          </p>
        </div>
      </div>
    </>
  );
};
