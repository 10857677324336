import { FC } from 'react';
import cn from 'classnames';

import { useHeaderOptionsHook, useMediaQueryWidthPicker } from '@/hooks';

import { NavbarDesktop } from '@/components/navigation/Navbar';
import { SiteLogo } from '@/components/common/SiteLogo';
import { HeaderNavigation } from './HeaderNavigation';

import { HeaderProps } from './Header.props';
import { SearchModal } from '@/components/common/SearchModal';

export const Header: FC<HeaderProps> = ({ menus = [] }) => {
  const { device } = useMediaQueryWidthPicker();
  const {
    sticky,
    navbarOpen,
    isRedHeroBg,
    isOpen,
    selected,
    closeModal,
    navbarToggleHandler,
    handleLocaleChange,
    openModal,
    isOnHeroNow,
  } = useHeaderOptionsHook(device);

  return (
    <header className="!fixed !z-[999] w-full">
      <div className="border-b border-primaryLight bg-white py-5 dark:bg-darkFullDark dark:text-darkFullLight md:py-8 lg:py-7">
        <div className="container relative flex items-center justify-between">
          <div className="flex items-center lg:gap-[60px]">
            <SiteLogo />

            <a
              className="hidden text-textPrimary transition-colors duration-300 hover:text-accentAdditional focus:text-accentAdditional dark:text-darkFullLight dark:hover:text-accentMain lg:block "
              href="tel:+380952869014"
            >
              +380952869014
            </a>
          </div>
          <HeaderNavigation
            menus={menus}
            sticky={sticky}
            navbarOpen={navbarOpen}
            selected={selected}
            isRedHeroBg={isRedHeroBg}
            onLocaleChange={handleLocaleChange}
            navbarToggleHandler={navbarToggleHandler}
            openModal={openModal}
            device={device}
            // isOnHeroNow={isOnHeroNow}
          />
        </div>
      </div>
      {device == 'desktop' && navbarOpen && sticky && (
        <div
          className={cn('relative -z-10 py-5', {
            'bg-accentAdditional': isRedHeroBg && isOnHeroNow,
            'bg-white dark:bg-darkFullDark dark:text-darkFullLight':
              !isRedHeroBg || !isOnHeroNow,
          })}
        >
          <div className="container flex justify-end">
            <NavbarDesktop
              menus={menus}
              isRedHeroBg={isRedHeroBg && (isOnHeroNow || !isRedHeroBg)}
            />
          </div>
        </div>
      )}
      {isOpen && <SearchModal isOpen={isOpen} closeModal={closeModal} />}
    </header>
  );
};
