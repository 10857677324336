import { FC } from 'react';
import Link from 'next/link';
import cn from 'classnames';
import { trimText } from '@/utils/trimText';
import { BreadcrumbsProps } from '@/types/types';
import { useCrumbsModifierHook } from '@/hooks/useCrumbsModifierHook';
import { useLocaleHook } from '@/hooks';

export type BreadcrumbProps = {
  className?: string;
  isDark?: boolean;
  breadcrumbs: BreadcrumbsProps[] | undefined;
};

export const Breadcrumbs: FC<BreadcrumbProps> = ({
  className,
  isDark = true,
  breadcrumbs = [],
}) => {
  const modifiedBreadcrumbs = useCrumbsModifierHook(breadcrumbs);
  const locale = useLocaleHook();
  const isUk = locale === 'uk';

  return (
    <div
      className={cn(
        'mb-15 md:mb-[46px] lg:mb-breadDeskM lg:pt-breadDeskP',
        className,
      )}
    >
      <ul className="flex items-center">
        {modifiedBreadcrumbs.map(({ text, url }) => {
          return (
            <li
              key={text}
              className={cn(
                "p-0 leading-none after:mx-2 after:whitespace-nowrap after:content-['->'] last:after:content-none",
                {
                  'after:text-primaryMiddle dark:after:text-darkFullLight':
                    isDark,
                  'after:text-white dark:after:text-darkFullDark': !isDark,
                },
              )}
            >
              {!url && text}

              {url && (
                <Link
                  href={url}
                  className={cn(
                    `text-sm font-normal transition-colors md:text-md`,
                    {
                      'text-primaryMiddle hover:text-accentMain focus:text-accentMain dark:text-darkFullLight dark:hover:text-accentMain dark:focus:text-accentMain':
                        isDark,
                      'text-white hover:text-primaryMiddle focus:text-primaryMiddle dark:text-darkFullDark dark:hover:text-darkFullLight dark:focus:text-darkFullLight':
                        !isDark,
                    },
                  )}
                  aria-label={
                    isUk ? `Перейти на сторінку ${text}` : `Go to ${text} page`
                  }
                >
                  {trimText(text, 25)}
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
