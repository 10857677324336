/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, SetStateAction, useLayoutEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';

import { Modal } from '@/components/common/Modal';
import { Search } from '@/components/inputs/Search';
import { getApolloClient } from '@/utils/apollo-client';
import Link from 'next/link';
import { Loader } from '@/components/common/Loader';
import { IconButton } from '@/components/buttons/IconButton';

const client = getApolloClient();

export interface SearchModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

export const SearchModal: FC<SearchModalProps> = ({ isOpen, closeModal }) => {
  const { t } = useTranslation('common');
  const [searchValue, setSearchValue] = useState<SetStateAction<string>>('');
  const [loading, setLoading] = useState<SetStateAction<boolean>>(false);
  const [data, setData] = useState<SetStateAction<any>>(null);
  const [isGrayscale, setIsGrayscale] =
    useState<SetStateAction<boolean>>(false);

  useLayoutEffect(() => {
    const grayscale = localStorage.getItem('grayscale');
    if (grayscale) {
      setIsGrayscale(JSON.parse(grayscale));
    }
  }, []);

  return (
    <ApolloProvider client={client}>
      <Modal
        closeModal={closeModal}
        isOpen={isOpen}
        className={cn('pt-16', {
          grayscale: isGrayscale,
        })}
      >
        <Search
          placeholder={t('searchPlaceholder')}
          setLoading={setLoading}
          setData={setData}
          setSearchValue={setSearchValue}
        />
        <div className="h-[calc(100%_-_48px)] overflow-y-auto">
          <div
            className={cn('relative inline-flex h-24 w-full items-end py-2', {
              'justify-center': loading,
              'justify-start': !loading,
            })}
          >
            {loading && <Loader />}
            <p
              className={cn(
                'pb-5 text-base leading-5 text-primaryMiddle dark:text-darkGrayPM',
                {
                  hidden: loading,
                  inline: !loading,
                },
              )}
            >
              <span>{t('searchLabel')}</span>
              {searchValue && data?.length === 0 && (
                <span className="ml-1 font-medium">
                  {t('searchNotFind')}
                  <span className="font-bold">{`"${searchValue}"`}</span>
                </span>
              )}
            </p>
          </div>
          {data && (
            <div className="h-[calc(100%_-_124px)] overflow-y-auto">
              <ul>
                {data.map((item: any) => {
                  return (
                    <li
                      key={item.id}
                      className="border-b border-primaryLight py-5 first:pt-0"
                    >
                      <Link
                        href={item.uri}
                        className="block transition-colors duration-300 hover:text-accentMain focus:text-accentMain"
                        onClick={closeModal}
                      >
                        {item.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        <IconButton
          icon="close"
          className="absolute right-0 top-0 inline-flex h-12 w-12 items-center justify-center text-primaryDark transition-colors duration-300"
          onClick={closeModal}
        />
      </Modal>
    </ApolloProvider>
  );
};
