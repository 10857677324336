/* eslint-disable no-unused-vars */
import ChevronIcon from '@/public/icons/chevron-icon.svg';
import { Listbox, Transition } from '@headlessui/react';
import { FC, Fragment } from 'react';

interface LanguageToggleProps {
  selected: string;
  onLocaleChange: (value: string) => void;
}

const languages = [
  { value: 'Укр', locale: 'uk' },
  { value: 'Eng', locale: 'en' },
];

export const LanguageToggle: FC<LanguageToggleProps> = ({
  selected,
  onLocaleChange,
}) => {
  return (
    <Listbox value={selected} onChange={onLocaleChange}>
      <div className="relative">
        <Listbox.Button className="relative flex w-full cursor-pointer items-center gap-0.5 text-lg capitalize text-textPrimary dark:text-darkFullLight dark:hover:text-accentMain ">
          {selected == 'uk' ? 'укр' : 'eng'}
          <ChevronIcon className="h-5 w-5 text-accentMain" aria-hidden="true" />
        </Listbox.Button>

        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options className="absolute z-50 mt-1 max-h-60 w-full rounded border bg-white text-base shadow-lg dark:bg-darkFullDark dark:text-darkFullLight dark:hover:text-accentMain sm:text-sm">
            {languages.map(language => (
              <Listbox.Option
                key={language.locale}
                className={({ active }) =>
                  `relative cursor-pointer select-none p-2 capitalize  ${
                    active ? 'bg-grStart dark:bg-darkFullDark' : ''
                  }`
                }
                value={language.locale}
              >
                {({ selected }) => (
                  <p
                    className={`text-center text-lg ${
                      selected
                        ? 'text-accentMain'
                        : 'text-textPrimary dark:text-darkFullLight dark:hover:text-accentMain'
                    }`}
                  >
                    {language.value}
                  </p>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};
