/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from 'react';
import cn from 'classnames';

import { LanguageToggle } from '@/components/common/LanguageToggle';
import { IconButton } from '@/components/buttons/IconButton';
import {
  NavbarDesktop,
  NavbarNotDesktop,
} from '@/components/navigation/Navbar';
import { AnimatedComponent } from '@/components/common/AnimatedComponent';

import { HeaderNavigationProps } from './Header.props';
import { useCurrentDeviceHeighPicker, useLocaleHook } from '@/hooks';
import { AccessibilityPopup } from '@/components/cards/AccessabilityPopup';

export const HeaderNavigation: FC<HeaderNavigationProps> = ({
  menus,
  sticky,
  navbarOpen,
  selected,
  isRedHeroBg,
  onLocaleChange,
  navbarToggleHandler,
  openModal,
  device,
}) => {
  const [isAccessibilityOpen, setIsAccessibilityOpen] = useState(false);
  const deviceHeight = useCurrentDeviceHeighPicker();
  const locale = useLocaleHook();
  const isUk = locale == 'uk';
  const mobile = device == 'mobile';
  const tablet = device == 'tablet';

  return (
    <>
      {mobile ? (
        <>
          <div
            className={cn('flex items-center', {
              'gap-6': !navbarOpen,
              'gap-10': navbarOpen,
            })}
          >
            {!navbarOpen && <IconButton icon="search" onClick={openModal} />}
            {!navbarOpen && (
              <div className="relative h-7 w-7">
                <IconButton
                  icon="eye"
                  onClick={() => setIsAccessibilityOpen(!isAccessibilityOpen)}
                  aria-label={
                    isAccessibilityOpen
                      ? isUk
                        ? 'Згорнути меню маніпулювання доступністю'
                        : 'Collapse accessibility manipulation menu'
                      : isUk
                      ? 'Розгорнути меню маніпулювання доступністю'
                      : 'Expand accessibility manipulation menu'
                  }
                />
                {isAccessibilityOpen && (
                  <AccessibilityPopup
                    close={() => setIsAccessibilityOpen(false)}
                  />
                )}
              </div>
            )}

            {navbarOpen && (
              <LanguageToggle
                selected={selected}
                onLocaleChange={onLocaleChange}
              />
            )}

            <IconButton
              icon={navbarOpen ? 'close' : 'burger'}
              onClick={navbarToggleHandler}
              className="text-accentMain"
            />
          </div>
          {navbarOpen && (
            <div
              className="container absolute -bottom-[21px] left-0 flex min-w-full translate-y-full justify-end overflow-y-auto bg-white py-10 dark:bg-darkFullDark dark:text-darkFullLight"
              style={{ height: `${deviceHeight - 71}px` }}
            >
              <AnimatedComponent
                className="h-full w-full"
                variants={{
                  hidden: { opacity: 0, y: '-100%' },
                  visible: { opacity: 1, y: 0 },
                }}
              >
                <NavbarNotDesktop menus={menus} />
              </AnimatedComponent>
            </div>
          )}
        </>
      ) : tablet ? (
        <div className="flex items-center gap-8">
          <IconButton icon="search" onClick={openModal} />
          <div className="relative h-7 w-7">
            <IconButton
              icon="eye"
              onClick={() => setIsAccessibilityOpen(!isAccessibilityOpen)}
              aria-label={
                isAccessibilityOpen
                  ? isUk
                    ? 'Згорнути меню маніпулювання доступністю'
                    : 'Collapse accessibility manipulation menu'
                  : isUk
                  ? 'Розгорнути меню маніпулювання доступністю'
                  : 'Expand accessibility manipulation menu'
              }
            />
            {isAccessibilityOpen && (
              <AccessibilityPopup close={() => setIsAccessibilityOpen(false)} />
            )}
          </div>
          <LanguageToggle selected={selected} onLocaleChange={onLocaleChange} />
          <IconButton icon="burger" onClick={navbarToggleHandler} />

          {navbarOpen && (
            <div
              className={cn(
                'fixed inset-0 flex justify-end bg-black bg-opacity-25 ',
              )}
            >
              <AnimatedComponent
                className="container absolute flex h-screen w-1/2 justify-end overflow-y-auto bg-white pb-10 pt-28 dark:bg-darkFullDark dark:text-darkFullLight"
                variants={{
                  hidden: { opacity: 0, x: '100%' },
                  visible: { opacity: 1, x: 0 },
                }}
              >
                {device == 'tablet' && (
                  <IconButton
                    icon="close"
                    onClick={navbarToggleHandler}
                    className={cn({
                      'absolute right-8 top-10 text-black': navbarOpen,
                    })}
                  />
                )}

                <NavbarNotDesktop menus={menus} />
              </AnimatedComponent>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="flex items-center gap-8">
            <IconButton icon="search" onClick={openModal} />
            <div className="relative h-7 w-7">
              <IconButton
                icon="eye"
                onClick={() => setIsAccessibilityOpen(!isAccessibilityOpen)}
                aria-label={
                  isAccessibilityOpen
                    ? isUk
                      ? 'Згорнути меню маніпулювання доступністю'
                      : 'Collapse accessibility manipulation menu'
                    : isUk
                    ? 'Розгорнути меню маніпулювання доступністю'
                    : 'Expand accessibility manipulation menu'
                }
              />
              {isAccessibilityOpen && (
                <AccessibilityPopup
                  close={() => setIsAccessibilityOpen(false)}
                />
              )}
            </div>

            <LanguageToggle
              selected={selected}
              onLocaleChange={onLocaleChange}
            />

            {sticky && (
              <IconButton
                icon={navbarOpen ? 'close' : 'burger'}
                onClick={navbarToggleHandler}
                className="text-accentMain"
              />
            )}
          </div>
          {(navbarOpen && !sticky) || (!navbarOpen && !sticky) ? (
            <div className="container absolute -bottom-[29px] right-0 -z-10 inline-flex w-fit translate-y-full justify-end bg-transparent py-5">
              <NavbarDesktop menus={menus} isRedHeroBg={isRedHeroBg} />
            </div>
          ) : null}
        </>
      )}
    </>
  );
};
