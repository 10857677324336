import { useEffect, useState } from 'react';
import { useMediaQueryWidthPicker } from './';

export const useCommonImageSizePicker = () => {
  const { screenWidth } = useMediaQueryWidthPicker();
  const [width, setWidth] = useState<number>(screenWidth);
  const [height, setHeight] = useState<number>(screenWidth);

  const tabletWidth = 768;
  const desktopWidth = 1280;

  useEffect(() => {
    screenWidth >= desktopWidth
      ? setWidth(352)
      : screenWidth >= tabletWidth
      ? setWidth(304)
      : setWidth(screenWidth - 104);
  }, [screenWidth]);

  useEffect(() => {
    screenWidth >= tabletWidth ? setHeight(194) : setHeight(width / 1.5);
  }, [width]);

  return { width, height };
};
