export const decodeText = (text: string | undefined): string | undefined => {
  if (!text) return;

  const entities: { [key: string]: string } = {
    '&#8217;': "'",
    '&#8220;': '"',
    '&#8221;': '"',
  };

  return text.replace(/&#[0-9]+;/g, match => entities[match] || match);
};
