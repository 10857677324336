import { FC, useEffect, useLayoutEffect, useState } from 'react';
import cn from 'classnames';
import { IconButton } from '@/components/buttons/IconButton';
import { Checkbox, FormControlLabel } from '@mui/material';
import {
  useLocaleHook,
  useMediaQueryWidthPicker,
  useDarkModeContext,
  useSetDarkModeContext,
} from '@/hooks';

import { AccessibilityPopupProps } from './AccessibilityPopup.props';

import CheckedIcon from '@/public/icons/accessibility-checked.svg';
import UncheckedIcon from '@/public/icons/accessibility-unchecked.svg';
import DATA from './data.json';

export const AccessibilityPopup: FC<AccessibilityPopupProps> = ({
  close,
  ...rest
}) => {
  /**
   * LABEL: Визначаємо типи шрифтів для доступності, де base - це найменший шрифт, large - середній, giant - найбільший.
   * За замовчанням встановлюємо той, що знаходиться в localStorage за ключом 'font', якщо немає, то base.
   */
  const fonts = ['base', 'large', 'giant'] as const;
  type FontSize = (typeof fonts)[number];
  const [fontDefaultSize, setFontDefaultSize] = useState<FontSize>(
    (localStorage.getItem('font') as FontSize) || 'large',
  );

  /**
   * LABEL: Визначаємо, чи включений чорно-білий режим, де true - включений, false - виключений.
   * За замовчанням встановлюємо чи включений режим за допомогою ключа 'grayscale', що знаходиться в localStorage.
   * Якщо немає, то true(при ініціалізації компонента кольори стають чорно-білими).
   */

  const [isGrayScaled, setIsGrayScaled] = useState<boolean>(
    localStorage.getItem('grayscale') !== null
      ? localStorage.getItem('grayscale') === 'true'
        ? true
        : false
      : true,
  );

  /**
   * LABEL: Визначаємо, чи включений режим без картинок, де true - включений, false - виключений.
   * За замовчанням встановлюємо чи включений режим за допомогою ключа 'grayscale', що знаходиться в localStorage.
   * Якщо немає, то о true(при ініціалізації компонента картинки зникають).
   */
  const [noImageMode, setNoImageMode] = useState<boolean>(
    localStorage.getItem('hide-images') !== null
      ? localStorage.getItem('hide-images') === 'true'
        ? true
        : false
      : true,
  );

  /**
   * LABEL: Визначаємо додаткові опії, що знадобляться нам для роботи компонента, а саме:
   * - пристрій, на якому відбувається відображення сайту  за допомогою хука useMediaQueryWidthPicker.
   * - локалізацію визначаємо за допомогою useRouter.
   * - назви розділів доступності визначаємо за допомогою об'єкта accessibilityItems в залежності від локалізації.
   */
  const { device } = useMediaQueryWidthPicker();
  const locale = useLocaleHook();
  const isUk = locale == 'uk';
  const language = isUk ? 'UK' : 'EN';
  const {
    SECTION: { FONT, BG_COLOR, VARIANT, RECOVER },
    CHECKBOX,
    ARIA: { THEME, NORMAL, GRAY_SCALE, NO_IMAGE },
  } = DATA[language];

  /**
   * LABEL: Змінні для роботи з контекстом теми.
   */
  const darkMode = useDarkModeContext();
  const setDarkMode = useSetDarkModeContext();

  const html = document.documentElement;

  /**
   * LABEL: Функція, що змінює тему в залежності від того, яка тема зараз встановлена.
   * @param theme string - тема, яку потрібно змінити
   * @returns dark | light - тема, яка повертається
   */
  function toggleTheme(theme: string): string {
    if (theme === 'dark') return 'light';
    return 'dark';
  }

  /**
   * LABEL: Функція, що вимикає чорно-білий режим та режим без картинок.
   */
  const handleDefaultVariant = () => {
    setIsGrayScaled(false);
    setNoImageMode(false);
  };

  /**
   * LABEL: Функція, що змінює розмір тип шрифту в залежності від того, який зараз встановлений а також від того,
   * чи шрифт збільшуються, чи зменшують. Є перевірка на пристрій, на якому відбувається відображення сайту.
   * В залежності від сукупності всіх вищезгаданих факторів, встановлюється розмір шрифту на головний елемент html.
   * В даному випадку ми встановлюємо не лише безпосередній розмір шрифту, а й його тип, який запишемо в сторедж, аби
   * якщо користувач закриє вкладку в режимі мобільного пристрою, а зайде в режимі, наприклад, десктопу, то шрифт
   * автоматично перестроїться на відповідний до типу, що зберігся в стореджі.
   *
   * @param value 'increase' | 'decrease' - значення, яке визначає, чи збільшувати шрифт, чи зменшувати
   * @returns void
   */
  const handleFontsChange = (value: 'increase' | 'decrease') => {
    if (value === 'increase') {
      if (fontDefaultSize === 'base') {
        setFontDefaultSize('large');
        html.style.fontSize = device === 'mobile' ? '19px' : '21px';
        return;
      }
      if (fontDefaultSize === 'large') {
        setFontDefaultSize('giant');
        html.style.fontSize = device === 'mobile' ? '22px' : '24px';
        return;
      }
    } else {
      if (fontDefaultSize === 'giant') {
        setFontDefaultSize('large');
        html.style.fontSize = device === 'mobile' ? '19px' : '21px';
        return;
      }
      if (fontDefaultSize === 'large') {
        setFontDefaultSize('base');
        html.style.fontSize = device === 'mobile' ? '16px' : '18px';
        return;
      }
    }
  };

  /**
   * LABEL: Функція, що відновлює дефолтні налаштування доступності, очищає localStorage від відповідних ключів за закриває вікно доступності.
   * Реалізовано за допомогою ланцюжка Promise.resolve, щоб вікно закрилося тільки після того, як відбудеться очищення  всіх необхідних
   * ключів localStorage.
   */
  //
  const handleRestore = async () => {
    return Promise.resolve(setIsGrayScaled(false)).then(() =>
      Promise.resolve(setNoImageMode(false))
        .then(() => Promise.resolve(setFontDefaultSize('base')))
        .then(() => Promise.resolve(setDarkMode('light')))
        .then(() =>
          Promise.resolve(
            (html.style.fontSize = device === 'mobile' ? '16px' : '18px'),
          ),
        )
        .then(() => Promise.resolve(localStorage.removeItem('grayscale')))
        .then(() => Promise.resolve(localStorage.removeItem('hide-images')))
        .then(() => Promise.resolve(localStorage.removeItem('font')))
        .then(() => Promise.resolve(localStorage.removeItem('theme')))
        .then(close),
    );
  };

  /**
   * LABEL: В залежності від того, чи включений чорно-білий режим, встановлюємо відповідні класи для елементів
   * 'header', 'footer', 'main'. Не встановлюємо класи для елементів 'html' та 'body', оскільки в такому випадку
   *  коли ввімкнений клас grayscale, то header втрачає властивість position: fixed. Також  записуємо в сторедж
   * значення, чи включений чорно-білий режим.
   */
  useEffect(() => {
    if (isGrayScaled) {
      ['header', 'footer', 'main'].forEach(el =>
        document.querySelector(el)?.classList.add('grayscale'),
      );
      localStorage.setItem('grayscale', 'true');
      return;
    } else {
      ['header', 'footer', 'main'].forEach(el =>
        document.querySelector(el)?.classList.remove('grayscale'),
      );
      localStorage.setItem('grayscale', 'false');
      return;
    }
  }, [isGrayScaled]);

  /**
   * LABEL: В залежності від того, чи включений режим без картинок, встановлюємо відповідний клас на елемент 'html'
   * та оновлюємо значення в localStorage за ключом 'hide-images'.
   */
  useEffect(() => {
    if (noImageMode) {
      html.classList.add('hide-images');
      localStorage.setItem('hide-images', 'true');
      return;
    } else {
      html.classList.remove('hide-images');
      localStorage.setItem('hide-images', 'false');
      return;
    }
  }, [noImageMode]);

  /**
   * LABEL: "Слухаємо" змінну fontDefaultSize і при її зміні записуємо в localStorage нове значення за ключом 'font'.
   */
  useEffect(() => {
    localStorage.setItem('font', fontDefaultSize);
  }, [fontDefaultSize]);

  /**
   * LABEL: При появі компонента, встановлюємо дефолтне збільшене значення шрифта.
   */
  useLayoutEffect(() => {
    const fontSize = localStorage.getItem('font');
    const screenWidth = window.innerWidth;
    if (!fontSize) {
      html.style.fontSize = screenWidth < 768 ? '19px' : '21px';
    }
  }, []);

  return (
    <ul
      className={cn(
        'absolute bottom-0 right-0 grid translate-y-full gap-4 rounded bg-white p-4 text-base text-textPrimary shadow-accessibility dark:border dark:border-darkFullLight dark:bg-darkFullDark dark:text-darkFullLight',
      )}
      {...rest}
    >
      <li className="grid">
        <p className="!mb-0 whitespace-nowrap border-b border-primaryLight pb-3">
          {FONT}
        </p>
        <div className="mt-3 flex items-center gap-2 text-xxl leading-7">
          <IconButton
            icon="minus"
            disabled={fontDefaultSize == 'base'}
            onClick={() => handleFontsChange('decrease')}
          />
          <span>Aa</span>
          <IconButton
            icon="plus"
            disabled={fontDefaultSize == 'giant'}
            onClick={() => handleFontsChange('increase')}
          />
        </div>
      </li>

      <li className="grid">
        <p className="!mb-0 whitespace-nowrap border-b border-primaryLight pb-3">
          {BG_COLOR}
        </p>
        <ul className="mt-3 flex gap-3">
          <li>
            <button
              className={cn(
                'h-7 w-7 rounded border border-primaryLight bg-white text-black disabled:cursor-not-allowed',
                {
                  'outline outline-accentMain': darkMode != 'dark',
                },
              )}
              disabled={darkMode != 'dark'}
              onClick={() => setDarkMode(toggleTheme(darkMode))}
              aria-label={THEME.LIGHT}
            >
              <span aria-hidden={true}>A</span>
            </button>
          </li>
          <li>
            <button
              className={cn(
                'h-7 w-7 rounded border border-black bg-black text-white disabled:cursor-not-allowed',
                {
                  'outline outline-accentMain': darkMode != 'light',
                },
              )}
              disabled={darkMode != 'light'}
              onClick={() => setDarkMode(toggleTheme(darkMode))}
              aria-label={THEME.DARK}
            >
              <span aria-hidden={true}>A</span>
            </button>
          </li>
        </ul>
      </li>

      <li className="grid">
        <p className="!mb-0 whitespace-nowrap border-b border-primaryLight pb-3">
          {VARIANT}
        </p>
        <fieldset className="flex flex-col">
          <FormControlLabel
            label={CHECKBOX.DEFAULT}
            className="whitespace-nowrap"
            checked={!isGrayScaled && !noImageMode}
            classes={{ disabled: '!text-inherit opacity-[0.8]' }}
            control={
              <Checkbox
                onChange={handleDefaultVariant}
                color="error"
                icon={<UncheckedIcon className="h-4 w-4 text-accentMain" />}
                checkedIcon={
                  <CheckedIcon className="h-4 w-4 text-accentMain" />
                }
                aria-label={NORMAL}
              />
            }
            disabled={!isGrayScaled && !noImageMode}
          />
          <FormControlLabel
            label={CHECKBOX.B_W}
            className="whitespace-nowrap"
            control={
              <Checkbox
                checked={isGrayScaled}
                onChange={() => setIsGrayScaled(!isGrayScaled)}
                icon={<UncheckedIcon className="h-4 w-4 text-accentMain" />}
                checkedIcon={
                  <CheckedIcon className="h-4 w-4 text-accentMain" />
                }
                aria-label={!isGrayScaled ? GRAY_SCALE.ON : GRAY_SCALE.OFF}
              />
            }
          />
          <FormControlLabel
            label={CHECKBOX.NO_IMAGES}
            checked={noImageMode}
            className="whitespace-nowrap"
            control={
              <Checkbox
                onChange={() => setNoImageMode(!noImageMode)}
                icon={<UncheckedIcon className="h-4 w-4 text-accentMain" />}
                checkedIcon={
                  <CheckedIcon className="h-4 w-4 text-accentMain" />
                }
                aria-label={!noImageMode ? NO_IMAGE.ON : NO_IMAGE.OFF}
              />
            }
          />
        </fieldset>
      </li>
      <li className="border-t border-primaryLight pt-3">
        <button
          className=" w-fill relative mx-auto inline-flex h-8 items-center gap-1 whitespace-nowrap rounded border border-accentMain py-1 pl-8 pr-2 text-md before:absolute before:left-1 before:top-1 before:h-6 before:w-6 before:bg-resetBtn before:transition-transform before:duration-300 before:ease-out hover:before:-rotate-[360deg]"
          onClick={handleRestore}
        >
          {RECOVER}
        </button>
      </li>
    </ul>
  );
};
