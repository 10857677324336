import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { ROUTES } from '@/utils/routes';

export const useHeaderOptionsHook = (
  device: 'mobile' | 'tablet' | 'desktop',
) => {
  const router = useRouter();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sticky, setSticky] = useState(false);
  const [isRedHeroBg, setIsRedHeroBg] = useState(false);
  const [navbarOpen, setNavbarOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selected, setSelected] = useState<any>(router.locale);
  const [isOnHeroNow, setIsOnHeroNow] = useState<boolean>(false);

  // Take type of device (mobile, tablet, desktop)

  // Routes for pages with red background
  const pageWithRedBackground = [
    ROUTES.HOME,
    ROUTES.ABOUT_US,
    ROUTES.COMPANY_HISTORY,
  ];

  // Option for desktop, when we activate sticky navbar
  const HEIGHT_SCROLL = 60;

  // Set option by background color of hero
  useEffect(() => {
    if (pageWithRedBackground.includes(router.pathname)) {
      setIsRedHeroBg(true);
    }
  }, [router]);

  // Sticky navbar handler
  useEffect(() => {
    const handleStickyNavbar = () => {
      if (window.scrollY >= HEIGHT_SCROLL) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', handleStickyNavbar);

    return () => {
      window.removeEventListener('scroll', handleStickyNavbar);
    };
  }, [sticky, router]);

  // Correct document spacing (for modal library) when we toggle modal
  useEffect(() => {
    const htmlElement = document.documentElement;
    const body = document.body;

    if (isOpen) {
      htmlElement.classList.add('!pr-0');
      body.classList.add('!mt-0');
      return;
    }

    htmlElement.classList.remove('pr-0');
    body.classList.remove('mt-0');
  }, [isOpen]);

  // Remove possibility to scroll body when navbar is open
  useEffect(() => {
    const body = document.body;

    if (navbarOpen && device != 'desktop') {
      body.classList.add('overflow-hidden');
      return;
    }
    return body.classList.remove('overflow-hidden');
  }, [device, navbarOpen]);

  useEffect(() => {
    if (navbarOpen && device == 'desktop') {
      window.addEventListener('scroll', () => setNavbarOpen(false));
    }
    return window.removeEventListener('scroll', () => setNavbarOpen(false));
  }, [device, navbarOpen]);

  useEffect(() => {
    const handleScroll = () => {
      if (device == 'desktop' && isRedHeroBg) {
        const scrolledPixels = window.scrollY;
        const heroHeight =
          document.querySelector('main > section')?.clientHeight || 0;
        if (scrolledPixels >= heroHeight) {
          setIsOnHeroNow(false);
        } else {
          setIsOnHeroNow(true);
        }
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [device, isRedHeroBg]);

  // Togglers for locale, modal and navbar
  const closeModal = () => setIsOpen(false);

  const openModal = () => setIsOpen(true);

  const navbarToggleHandler = () => setNavbarOpen(!navbarOpen);

  const handleLocaleChange = (value: string): void => {
    setSelected(value);

    const slug = localStorage.getItem('translated_slug');

    if (slug) {
      router.push(router.route, slug, { locale: value });
      return;
    }
    router.push(router.route, router.asPath, { locale: value });
  };

  return {
    sticky,
    navbarOpen,
    isRedHeroBg,
    isOpen,
    selected,
    closeModal,
    navbarToggleHandler,
    handleLocaleChange,
    openModal,
    isOnHeroNow,
  };
};
