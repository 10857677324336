import { useEffect, useState } from 'react';

export const useMediaQueryWidthPicker = () => {
  const [screenWidth, setScreenWidth] = useState<number>(0);
  const [device, setDevice] = useState<'mobile' | 'tablet' | 'desktop'>(
    'mobile',
  );

  const tablet = 768;
  const desktop = 1280;

  useEffect(() => {
    let resizeTimer: ReturnType<typeof setTimeout> | null = null;

    const handleResize = () => {
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }
      resizeTimer = setTimeout(() => {
        const screenWidth = window.innerWidth;
        setScreenWidth(screenWidth);
        if (screenWidth < tablet) {
          setDevice('mobile');
        } else if (screenWidth < desktop) {
          setDevice('tablet');
        } else {
          setDevice('desktop');
        }
      }, 100); // Задержка в миллисекундах перед обновлением размера экрана
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      if (resizeTimer) {
        clearTimeout(resizeTimer);
      }
      window.removeEventListener('resize', handleResize);
    };
  }, [setDevice, setScreenWidth]);

  return { screenWidth, device };
};
